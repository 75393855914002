import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

function TextArea(
    {
        content, 
        onUpdate
    }){

    const editorRef = useRef(null);
    
    function onEditorUpdate(value){
        onUpdate(value)
    }
    return(
        <Editor
            onInit={(evt, editor) => editorRef.current = editor}
            onEditorChange={(value) => onEditorUpdate(value)} 
            initialValue={`${content.toString()}`}
            apiKey='0v4vqgzzacf0f5h80xq6alva3zcmzxb4p1ud6oksm2189ijn'
            init={{
                height: 300,
                menubar: false,
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                ],
                formats: {
                    h1: { block: 'h1', classes: 'heading' }
                },
                style_formats: [
                    { title: 'h1', block: 'h1' },
                ],
                toolbar: 'undo redo | ' +
                'bold italic | ' +
                'bullist numlist outdent indent | ' +
                'removeformat',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />
    )

}

export default TextArea;