import {React} from "react";
import { getFileName } from '../../helpers/FileCatching';
const contentImages = require.context('../../images/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function APFLogo({}){
    return(
        <div>
           <img src={getFileName(contentImages,'Logo.svg').default} alt="APF Careers"/>
        </div>
    );
}

export default APFLogo;