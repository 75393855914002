import './Vacancies.scss';
import {React} from "react";
import Vacancy from '../../Components/Vacancy/Vacancy';
import { MDBBtn } from 'mdb-react-ui-kit';
function Vacancies({sampleContent, actualContent, onChange, onNewVacancy, onRemove}) {

    function onSingleVacancyChange(index, value, key){
        onChange(index, value, key)
    }

    function onSingleVacancyRemoved(index){
        onRemove(index)
    }

    return(
        <>
            {
                actualContent.map((element, index) =>{
                    return (
                        <Vacancy 
                            key={index} 
                            content={element} 
                            sampleContent={sampleContent} 
                            onChange={(value, key) => {onSingleVacancyChange(index, value, key)}}
                            onRemove={() => onSingleVacancyRemoved(index)}/>
                    )
                })
            }
            <MDBBtn onClick={() => {onNewVacancy()}} tag='a' className={`Editor__container__vacancies__new_vacancy`}>
                Add a new vacancy
            </MDBBtn>
        </>
    )

}

export default Vacancies;