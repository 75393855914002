import classes from './Vacancy.module.scss';
import {React, useState, useEffect} from "react";
import InputComponent from '../InputComponent';
import { MDBCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
function Vacancy({content, sampleContent, onChange, onRemove}) {

    const [isCollapsed, setIsCollapsed] = useState();

    function onVacancyChange(value, key){
        onChange(value, key)
    }

    function onDeletePress(){
        if (window.confirm('Are you sure you want to remove this item?')) {
            onRemove()
        }
    }

    useEffect(() => {
        if(content['title'] === ''){
            setIsCollapsed(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <>
            <div className={classes.Vacancy}>
                <div className={classes.Vacancy__content_container}>
                    <div className={classes.Vacancy__content_title}>
                        <span className={classes.Vacancy__vacancy_info__number}>{content['vacancy_number']}</span>
                        <span className={classes.Vacancy__vacancy_info__job_title}>{content['title']}</span>
                    </div>
                    
                    
                    <div className={classes.Vacancy__update}>
                        <div className={classes.Vacancy__delete}>
                            <MDBBtn onClick={() => onDeletePress()}>
                                <MDBIcon icon="times" />
                                
                            </MDBBtn>
                        </div>
                        
                        <div className={classes.Vacancy__visible}>
                            <InputComponent
                                className={`${classes.Vacancy__titleItem} ${classes.Vacancy__titleItem__visible}`} 
                                content={content['visible']} 
                                contentKey={'visible'} 
                                sampleContent={sampleContent} 
                                onUpdate={(value) => onVacancyChange(value, 'visible')}
                            />
                        </div>

                        <div className={classes.Vacancy__view}>
                            <MDBBtn 
                                className={`${classes.Vacancy__titleItem} .custom-switch`}
                                tag='a'
                                onClick={() => {
                                    setIsCollapsed(!isCollapsed)}}
                                >
                                {(isCollapsed ? <MDBIcon icon="angle-up" /> : <MDBIcon icon="angle-down" />)}
                            </MDBBtn>
                        </div>
                    </div>
                </div>
                <MDBCollapse 
                    center
                    className={classes.Vacancy__collapse_container} 
                    show={isCollapsed}>
                    {Object.keys(content).map((key, index) => {
                        switch (key) {
                            case "visible":
                                return ""
                            default:
                                return (
                
                                    <InputComponent 
                                        key={index}
                                        className={`${classes.Vacancy__collapse_container__input_item} testing`} 
                                        content={content[key]} 
                                        contentKey={key} 
                                        sampleContent={sampleContent}
                                        onUpdate={(value) => onVacancyChange(value, key)}/>
                                
                                )
                        }
                    
                    })}
                </MDBCollapse>
            </div>
        </>
    )

}

export default Vacancy;